import {Nullable} from "@app/com";
import * as React from "react";

export class ErrorBoundary extends React.Component<Record<string, any>, {error: unknown}> {
    public state: {error: Nullable<Error>} = {error: null};

    static getDerivedStateFromError(error: unknown) {
        return {error};
    }

    public componentDidCatch(_error: unknown, _errorInfo: any) {
        // @todo
    }

    public render() {
        const {error} = this.state;

        if (error) {
            return <>{error.message}</>;
        }

        return <>{this.props.children}</>;
    }
}
