export type DispatcherListener<A extends unknown[]> = (...args: A) => unknown;

export class Dispatcher<T extends Record<string, unknown[]>> {
    readonly #listeners: Map<unknown, Set<DispatcherListener<any>>> = new Map();

    public listen<K extends keyof T>(event: K, listener: DispatcherListener<T[K]>): () => void {
        const set = this.#listeners.get(event) ?? new Set();
        set.add(listener);

        if (!this.#listeners.has(event)) {
            this.#listeners.set(event, set);
        }

        return (): void => {
            set.delete(listener);
        };
    }

    public fire<K extends keyof T>(event: K, ...args: T[K]): void {
        for (const fn of this.#listeners.get(event)?.values() ?? []) {
            fn(...args);
        }
    }

    public count<K extends keyof T>(event: K): number {
        return this.#listeners.get(event)?.size ?? 0;
    }
}
