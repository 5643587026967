import {STORAGE_URL} from "../config";
import {isObject} from "../fn";
import {Nullable} from "../interfaces";
import {ImageFile, ImageSize} from "./interfaces";

export type MimeTypeList = "image/svg+xml" | "image/png";

const clean = (data: string) => data.trim();

export function dataUrl(type: MimeTypeList, data: string) {
    return `data:${type};base64,${btoa(clean(data))}`;
}

const baseBlob = "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg==";
export const baseImageDataURL = dataUrl("image/png", baseBlob);
export const imageDensitiesSrcSet = [1, 1.5, 2];

function getImageURL(file: ImageFile, size: ImageSize, ratio = 1) {
    const width = getSizeArg(ratio, size.w);
    const height = getSizeArg(ratio, size.h);

    return `${STORAGE_URL}/i/${width}/${height}/${file.bucket}/${file.id}`;
}

function getSizeArg(ratio: number, size?: number) {
    return size ? Math.floor(size * ratio) : "-";
}

export function getImageSrcSet(file: Nullable<ImageFile>, size: number | ImageSize) {
    if (!file) {
        return {src: baseImageDataURL};
    }

    const normalizedSize = isObject(size) ? size : {w: size};

    const srcSet = imageDensitiesSrcSet
        .map((ratio) => [ratio, getImageURL(file, normalizedSize, ratio)])
        .map(([ratio, url]) => `${url} ${ratio}x`)
        .join(", ");

    return {
        src: getImageURL(file, normalizedSize),
        srcSet,
    };
}
