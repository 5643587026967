import {UserAuthorizationPayload, UserRole, UserSession} from "@app/types";

import {createRequest} from "../endpoint";
import {noop} from "../fn";
import {useAtomState} from "../hooks";
import {UserSessionAtom} from "./UserSessionAtom";

const check = createRequest<UserSession, {role?: UserRole}>({uri: "/session", method: "POST"});
const authorize = createRequest<UserSession, UserAuthorizationPayload>({uri: "/authorize"});
const logout = createRequest({uri: "/session", method: "DELETE"});

const role: UserRole | undefined = USER_ROLE ? USER_ROLE as UserRole : undefined;

export class Session {
    private static error?: Error;
    private static session?: UserSession | null;

    public static authorize(payload: UserAuthorizationPayload): Promise<void> {
        return authorize({payload})
            .then(this.setSession)
            .then(noop);
    }

    public static useSession(session?: UserSession): UserSession | null {
        if (session) {
            this.setSession(session);

            return session;
        }

        if (typeof this.session === "undefined") {
            throw check({payload: {role}})
                .then(this.setSession)
                .catch(this.setSession);
        }

        return useAtomState(UserSessionAtom);
    }

    public static logout = async (): Promise<void> => {
        await logout().then(() => this.setSession(null));
    };

    public static reload = async (): Promise<void> => {
        await check({payload: {role}})
            .then(this.setSession)
            .catch(this.setSession);
    };

    public static setProperty<K extends keyof UserSession>(key: K, value: UserSession[K]): void {
        if (this.session) {
            this.setSession({...this.session, [key]: value});
        }
    }

    public static update(update: Partial<UserSession>): void {
        if (this.session) {
            this.setSession({...this.session, ...update});
        }
    }

    public static setSession = (session: Readonly<UserSession> | null): void => {
        this.session = session;

        if (session) {
            UserSessionAtom.authorize.dispatch(session);
        } else {
            UserSessionAtom.logout.dispatch();
        }
    };
}
