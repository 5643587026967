import {ScrollTop} from "@app/com";
import * as React from "react";
import {Suspense} from "react";
import {Outlet} from "react-router-dom";

import {Footer} from "./Layout/Footer";
import {Header} from "./Layout/Header";

export const Layout: React.FC = () => {
    return (
        <>
            <Header/>
            <Suspense fallback={<ScrollTop/>}>
                <Outlet/>
            </Suspense>
            <Footer/>
        </>
    );
};
