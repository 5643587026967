import * as React from "react";

import {AsyncQuery} from "./AsyncQuery";
import {ClientContext} from "./ClientContext";

export type ClientQueryProps = {
    children: React.ReactNode;
    endpoint: string;
    path: string;
};

export const ClientConnect: React.FC<ClientQueryProps> = ({children, endpoint, path}) => {
    const url = `${endpoint}${path}`;

    React.useEffect(() => (AsyncQuery.url = url, void 0), [url]);

    return (
        <ClientContext.Provider value={url}>
            {children}
        </ClientContext.Provider>
    );
};
