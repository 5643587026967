import * as React from "react";

import {ENDPOINT} from "../config";

const trimLeadingSlash = (uri: string): string => uri.replace(/^\//, "");
const concat = (...uri: string[]): string => uri.map(trimLeadingSlash).join("/");

export type EndpointResponse<T> = {data: T} | {error: Error};
export type EndpointState<T> = {data: T} | {error: Error} | null;
export type ResponseListener<T> = (value: EndpointResponse<T>) => void;
export type RequestAction<T> = (listener: ResponseListener<T>) => () => void;
export type DelayedRequest<T, P> = (data?: RequestData<P>) => Promise<T>;
export type RequestOptions = {
    uri: string;
    abort?: AbortController;
    method?: "GET" | "POST" | "DELETE" | "PUT" | "PATCH";
    mode?: RequestMode;
    credentials?: RequestCredentials;
};

export type RequestData<P> = {
    payload?: P;
    uri?: string;
};

export function createRequest<T = void, P = never>(options: RequestOptions): DelayedRequest<T, P> {
    const {uri, abort} = options;
    const headers: Record<string, string> = {
        "accept": "application/json",
    };

    const requestOptions: RequestInit = {
        signal: abort?.signal,
        method: options.method,
        credentials: options.credentials ?? "include",
        mode: options.mode ?? "cors",
    };

    requestOptions.headers = headers;

    return async (data?: RequestData<P>) => {
        if (data?.payload) {
            requestOptions.method = requestOptions.method ?? "POST";
            requestOptions.body = JSON.stringify(data.payload);
            headers["content-type"] = "application/json";
        }

        const response = await fetch(concat(ENDPOINT, data?.uri ?? uri), requestOptions);
        if (response.status === 200) {
            return response.json();
        }

        const details = await response.json()
            .catch(() => ({error: "Unexpected reply from server"}));

        throw new Error(details.error ?? "Wrong error");
    };
}

function performRequest<T>(uri: string | RequestOptions): RequestAction<T>;
function performRequest<T, P>(uri: string | RequestOptions, payload: P): RequestAction<T>;
function performRequest<T, P>(uri: string | RequestOptions, payload?: any): RequestAction<T> {
    const abort = new AbortController();
    const request = createRequest<T, P>(typeof uri === "string" ? {uri, abort} : {...uri, abort});

    return (listener: ResponseListener<T>): () => void => {
        request(payload)
            .then((data) => listener({data}))
            .catch((error) => listener({error}));

        return () => abort.abort();
    };
}

export function useRequest<T>(uri: string | RequestOptions): {data: T} | null;
export function useRequest<T, P>(uri: string | RequestOptions, payload: P): {data: T} | null;
export function useRequest<T, P>(uri: string | RequestOptions, payload?: any): {data: T} | null {
    const [state, setState] = React.useState<EndpointState<T>>(null);

    React.useEffect(() => performRequest<T, P>(uri, payload)(setState), [JSON.stringify(uri, payload)]);

    if (state && "error" in state) {
        throw state.error;
    }

    if (state && "data" in state) {
        return {data: state.data};
    }

    return null;
}

export type RequestErrorHandleArg = (() => unknown) | Promise<unknown>;

export type RequestErrorHandle = [
    functions: {
        setReason: (reason: unknown) => void;
        handleRequest: (cond: RequestErrorHandleArg, err?: (reason: string) => void) => Promise<boolean>;
        reset: () => void;
    },
    error?: string
];

export function useRequestError(unexpected = "Unexpected error"): RequestErrorHandle {
    const [error, setError] = React.useState<string | undefined>();

    React.useEffect(() => setError(undefined), []);

    return [
        React.useMemo(
            () => {
                return {
                    setReason: (reason: unknown) => {
                        setError(reason instanceof Error ? reason.message : unexpected);
                    },
                    handleRequest: async (cond: RequestErrorHandleArg, err) => {
                        setError(undefined);

                        try {
                            await (typeof cond === "function" ? cond() : cond);

                            return true;
                        } catch (reason) {
                            const error = reason instanceof Error ? reason.message : unexpected;
                            setError(error);
                            err?.(error);
                        }

                        return false;
                    },
                    reset: () => {
                        setError(undefined);
                    },
                };
            },
            [unexpected],
        ),
        error,
    ];
}

export type OperationResult = {
    reason?: string;
    pending: boolean;
};

export type OperationState = [
    result: OperationResult,
    functions: {
        run(op: RequestErrorHandleArg, handle?: (reason: string) => void): Promise<boolean>;
        useCallback(op: () => unknown, deps?: any[]): () => Promise<boolean>;
        useCallback(op: () => unknown, handle?: (reason: string) => void, deps?: any[]): () => Promise<boolean>;
        trigger(reason: unknown): void;
        reset(): void;
    },
];

function isErrorLike(reason: unknown): reason is {message: string} {
    return !!reason && typeof reason === "object" && "message" in reason;
}

function getReasonMessage(reason: unknown, unexpected = "Unexpected error"): string {
    if (reason instanceof Error) {
        if (Reflect.get(reason, "code") === "ACTION_REJECTED") {
            return "User cancelled";
        }

        return reason.message;
    }

    if (isErrorLike(reason)) {
        return reason.message;
    }

    return typeof reason === "string" ? reason : unexpected;
}

export type OperationArgs = string | {message?: string; success?: () => unknown; error?: (reason: string) => unknown};

export function useOperation(args: OperationArgs = {message: "Unexpected error"}): OperationState {
    const [state, setState] = React.useState<OperationResult>({pending: false});
    const {message, success = undefined, error = undefined} = typeof args === "string" ? {message: args} : args;

    React.useEffect(() => setState({pending: false}), [message]);

    const trigger = React.useCallback(
        (reason: unknown, pending?: boolean) => {
            setState((prev) => ({
                pending: pending ?? prev.pending,
                reason: getReasonMessage(reason, message),
            }));
        },
        [message],
    );

    const run = React.useCallback(
        async (op: RequestErrorHandleArg, handle?: (reason: string) => void) => {
            setState({reason: void 0, pending: true});

            try {
                await (typeof op === "function" ? op() : op);
                setState({pending: false});
                success?.();

                return true;
            } catch (reason) {
                error?.(getReasonMessage(reason, message));
                handle?.(getReasonMessage(reason, message));
                trigger(reason, false);
            }

            return false;
        },
        [message],
    );

    return [
        state,
        React.useMemo(
            () => ({
                trigger,
                run,
                useCallback(op: () => unknown, ...args: any[]) {
                    const deps: any[] = args.find((arg) => Array.isArray(arg)) ?? [];
                    const handle = args.find((arg) => typeof arg === "function");

                    return React.useCallback(() => run(op, handle), [handle, ...deps]);
                },
                reset: () => {
                    setState(({pending}) => ({pending}));
                },
            }),
            [message],
        ),
    ];
}
