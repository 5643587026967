import {ProgressPageRead} from "@com/progress";
import * as React from "react";

/* eslint-disable max-len */

export const Policy: React.FC = () => {
    return (
        <ProgressPageRead>
            <main>
                <section className="policy">
                    <div className="container">
                        <h1>Privacy Policy - W3G</h1>

                        <h2>Introduction</h2>
                        <p>
                    W3G (“we,” “us,” “our” or “W3G”, “W3G.CAPITAL”) respects your privacy and is committed to protecting it through our privacy practices as described in this privacy policy (hereinafter referred to as the Policy).
                        </p>
                        <p>
                    This Policy outlines the personal information we collect, how it is used, and the choices you have with regard to your personal information. This Policy applies to the personal information we collect through our websites, mobile applications, and any other website or mobile application owned by us (collectively “Services”) that posts a link to this Policy.
                        </p>
                        <p>
                    Please read this Policy carefully to understand our policies and practices regarding your personal information. Use of our Services indicates your consent to these practices described in this Policy. If you do not agree with the practices described in this Policy, you should not use any of our Services. This Policy may change from time to time (see “Changes to Our Privacy Policy”) and your continued use of our Services after we make changes is deemed to be acceptance of those changes.
                        </p>
                        <p>
                    This privacy policy regarding the processing of personal data applies to all information that W3G (hereinafter referred to as the Operator) can receive about visitors to the <a href="https://W3G.CAPITAL">https://W3G.CAPITAL</a> website.
                        </p>

                        <h2>Children Under the Age of 18</h2>
                        <p>
                    Our services, content and data are targeted for adults and business entities. Our Sites are therefore not meant for persons who may be defined as minors under applicable law. If you are a minor, please discontinue the use of our Site and all Site Services immediately.
                        </p>

                        <h2>Information We May Collect</h2>
                        <p>We collect data via cookies, which are small text files that are used to uniquely identify your browser. Cookies are placed onto your computer by our system and can later be accessed by our servers to get the statistics we need for site optimization. W3G collects only aggregated depersonalized data, which includes technologies (devices, operating system, etc.), traffic sources, as well as user interactions on the site.  </p>
                        <h2>Third-Party Use of Cookies and Other Tracking Technologies </h2>
                        <p>
                    We may allow third parties to serve content or applications, including advertisements, on our Services. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our Services. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content. We use third-party service providers, including Google, which uses cookies and similar technologies to collect and analyze information about your use of our Services and in-store technologies. These service providers may also collect information regarding your use of other websites, apps, and online resources. You can learn about Google’s practices at <a href="http://www.google.com/policies/privacy/partners/" target={"_blank"} rel="noopener">http://www.google.com/policies/privacy/partners/</a>, and opt out by using the Google Analytics Opt-Out Browser Add-on, available at <a href="https://tools.google.com/dlpage/gaoptout" target={"_blank"} rel="noopener">https://tools.google.com/dlpage/gaoptout</a>.
                        </p>
                        <h2>Data Security </h2>
                        <p>
                    We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.
                        </p>
                        <p>
                    The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Services, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information.
                        </p>
                        <p>
                    Unfortunately, the transmission of information via the internet is not completely secure.
                        </p>
                        <p>
                    Although we take measures to protect your personal information, we cannot guarantee the security of your personal information transmitted through our Services. Any transmission of personal information is at your own risk. We are not responsible for the circumvention of any privacy settings or security measures contained on our Services.
                        </p>

                        <h2>How We Use and Disclose Your Information </h2>
                        <p>
                    We use Non-Personally Identifiable Information (PII) to test and optimize the design and technical performance of our website, improve the usefulness and overall usability of our website, search for and correct errors, as well as to provide you with content relevant to your interests and requests.
                        </p>
                        <p>We may also use your information to contact you about our own and third-parties’ goods and services that may be of interest to you. At times, we may share your information with third parties who may market our services to you, or contact you about a survey or other research regarding our operations. </p>
                        <p>Our Services may include third-party advertising and links to other Web sites. We do not have access to or control over the privacy practices, cookies or other features that advertisers and third-party sites may use, and the information practices of these advertisers and third party websites are not covered by this Policy. We accept no responsibility or liability and makes no representations about the policies of these third parties. It is your responsibility to read these third parties’ privacy policies before visiting their site or choosing to share information with them. </p>
                        <p>We may disclose aggregated information about our users, and information that does not identify any individual, according to local law. </p>
                        <h2>Changes to Our Privacy Policy </h2>
                        <p>
                    If changes to this Policy are made, we will notify you by revising the date on this Policy. We encourage you to stay informed about our privacy practices by reviewing the Policy whenever you interact with us. In certain cases, we may provide you with additional notice of changes to this Policy. Any change to this Policy will be effective immediately and your continued use of W3G Services indicates your consent.
                        </p>
                        <h2>Contact Us</h2>
                        <p>
                    If you have any questions about this Policy or would like to request access to your personal information, please contact us to <a href="mailto:investor.relations@w3g.capital">investor.relations@w3g.capital</a>.
                        </p>
                    </div>
                </section>
            </main>
        </ProgressPageRead>
    );
};
