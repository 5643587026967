import {UserSession} from "@app/types";
import {createAtom} from "@reatom/core";

export const UserSessionAtom = createAtom(
    {
        authorize: (session: Readonly<UserSession>) => session,
        logout: () => void 0,
    },
    ({onAction}, state: UserSession | null = null) => {
        onAction("authorize", (session) => (state = session));
        onAction("logout", () => (state = null));

        return state;
    },
);
