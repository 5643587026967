import {ProgressPageRead} from "@com/progress";
import {SendApplication} from "@query/config";
import * as React from "react";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";

export const FundingApplication: React.FC = () => {
    const {register, handleSubmit} = useForm({shouldUseNativeValidation: true});
    const [thx, setThx] = React.useState(false);
    const onSubmit = handleSubmit((payload) => {
        SendApplication.mutate({payload});
        setThx(true);
    });

    return (
        <ProgressPageRead>
            <main>
                <section className="form">
                    <div className="container">
                        <h1 className="title">Funding Application</h1>
                        <form onSubmit={onSubmit}>
                            <input type="hidden" value={"Funding Application"} {...register("type")}/>
                            <div className="row">
                                <div className="com-input">
                                    <input {...register("email", {required: true})}
                                        placeholder="Email *"
                                        autoComplete="off"
                                        type="email" />
                                </div>
                                <div className="com-input">
                                    <input {...register("project", {required: true})}
                                        name="project"
                                        placeholder="Project name *"
                                        autoComplete="off"
                                        type="text" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="com-input">
                                    <input {...register("site", {
                                        required: true,
                                        pattern: {
                                            value: /^https?:\/\/.+/,
                                            message: "Invalid website",
                                        },
                                    })}
                                    placeholder="Project website *"
                                    autoComplete="off"
                                    type="text" />
                                </div>
                                <div className="com-input">
                                    <input {...register("blockchain", {required: true})}
                                        placeholder="What blockchain is the project built on? *"
                                        autoComplete="off"
                                        type="text" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="com-input">
                                    <input {...register("raising", {required: true})}
                                        name="raising"
                                        placeholder="How much in USD are you raising in total? *"
                                        autoComplete="off"
                                        min={1}
                                        type="number" />
                                </div>
                                <div className="com-input">
                                    <input {...register("raised", {required: true})}
                                        name="raised"
                                        placeholder="How much in USD have you raised so far? *"
                                        autoComplete="off"
                                        min={1}
                                        type="number" />
                                </div>
                            </div>
                            <div className="com-input">
                                <input {...register("highest", {required: true})}
                                    name="highest"
                                    placeholder="Who are you highest market cap competitors? *"
                                    autoComplete="off"
                                    type="text" />
                            </div>
                            <div className="com-input">
                                <input {...register("support", {required: true})}
                                    placeholder="How much financial support would you need from us? *"
                                    autoComplete="off"
                                    type="text" />
                            </div>
                            <div className="com-input">
                                <input {...register("contact", {required: true})}
                                    placeholder="Contact name *"
                                    autoComplete="off"
                                    type="text" />
                            </div>
                            <div className="com-input">
                                <input {...register("telegram", {
                                    required: true,
                                    pattern: {
                                        value: /^@[a-z0-9_\-.]{4,}$/,
                                        message: "Invalid telegram username",
                                    },
                                })}
                                placeholder="Contact telegram username (starts with @) *"
                                autoComplete="off"
                                type="text" />
                            </div>
                            <button type="submit" className="com-btn"><span>Submit</span></button>
                        </form>
                    </div>
                </section>
                {thx &&
                <div className="thx" onClick={() => setThx(false)}>
                    <div className="content">
                        <div className="title">Well done!</div>
                        <div className="descr">
                            Your application has been accepted for consideration.
                            Track the verification status via contacts, it usually doesn't take long time.
                        </div>
                        <Link to="/" className="com-btn small"><span>Back Home Page</span></Link>
                    </div>
                </div>
                }
            </main>
        </ProgressPageRead>
    );
};
