import {LinkTop} from "@com/LinkTop";
import * as React from "react";
import {Link, useLocation} from "react-router-dom";

export const Header: React.FC = () => {
    const {hash, state} = useLocation();
    React.useEffect(
        () => {
            if (state?.top) {
                window.scrollTo(0, 0);
            }

            if (hash) {
                document.querySelector(hash)?.scrollIntoView();
            }
        },
        [hash, state?.top],
    );

    return (
        <header className="header">
            <div className="container">
                <LinkTop to="/" className="logo">
                    <img src="/assets/img/logo.svg" alt="W3G LOGO" />
                </LinkTop>
                <nav>
                    <ul>
                        <li><Link to="/#about">About us</Link></li>
                        <li><Link to="/#focuses">Focuses</Link></li>
                        <li><Link to="/#figures">Figures</Link></li>
                        <li><Link to="/#mission">Mission</Link></li>
                    </ul>
                </nav>
                <a 
                    href="https://app.w3g.capital" 
                    target="_blank" 
                    className="com-btn small border" 
                    rel="noopener"
                >
                    <span>Login For Clients</span>
                </a>
            </div>
        </header>
    );
};
