import {ClientConnect, SentryLoader} from "@app/com";
import {ErrorBoundary} from "@com/ErrorBoundary";
import {ENDPOINT} from "@config";
import * as React from "react";

import Application from "./Application";

export const Bootstrap: React.FC = () => {
    return (
        <React.Suspense>
            <ErrorBoundary>
                <ClientConnect endpoint={ENDPOINT} path="/graph/public">
                    <Application/>
                </ClientConnect>
                <SentryLoader/>
            </ErrorBoundary>
        </React.Suspense>
    );
};
