import * as React from "react";
import {Link} from "react-router-dom";

export const SignIn: React.FC = () => {
    return (
        <main className="page-login">
            <div className="container">
                <form className="form">
                    <div className="title">Login</div>
                    <div className="new-acc">
                        Don't have an account? <Link to="/membership-application/">Register</Link>
                    </div>
                    <div className="com-input">
                        <input id="formEmail" name="email" placeholder="Email *" required autoComplete="off" type="email" />
                    </div>
                    <div className="com-input">
                        <input id="formPassword" name="password" placeholder="Password *" required autoComplete="off" type="password" />
                    </div>
                    <button className="com-btn small"><span>Log In</span></button>
                </form>
            </div>
        </main>
    );
};
