import {useEffect} from "react";
import * as React from "react";

export type Props = {
    children: React.ReactElement;
};

export const ProgressPageRead: React.FC<Props> = ({children}) => {
    const ref = React.useRef<HTMLElement>(null);
    const component = React.cloneElement(children, {ref});

    return (
        <>
            <ProgressBar container={ref}/>
            {component}
        </>
    );
};

const ProgressBar: React.FC<{container: React.RefObject<HTMLElement>}> = ({container}) => {
    const ref = React.useRef<HTMLDivElement>(null);

    useEffect(
        () => {
            const {current: bar} = ref;
            const {current: target} = container;
            if (bar && target) {
                const rect = target.getBoundingClientRect();
                const update = (): void => {
                    const scrollY = window.scrollY - rect.top;
                    const scrollHeight = rect.height - window.innerHeight;
                    const progress = scrollY > rect.top
                        ? scrollY / scrollHeight * 100
                        : 0;

                    bar.style.width = `${progress < 100 ? progress : 100}%`;
                };

                window.addEventListener("scroll", update);

                return () => window.removeEventListener("scroll", update);
            }
        },
        [container.current, ref.current],
    );

    return <div className="progress-bar" style={{width: "0%"}} ref={ref} />;
};
