import {UserSession} from "@app/types";
import {createAtom} from "@reatom/core";

import {validate} from "../fn";
import {UserSessionAtom} from "./UserSessionAtom";

export const ProfileAtom = createAtom(
    {
        UserSessionAtom,
        update: (session: UserSession | null) => session,
    },
    ({onChange, get}, state = get("UserSessionAtom")) => {
        validate(state, "Authorization failed");

        onChange("UserSessionAtom", (nextState) => {
            if (nextState) {
                state = nextState;
            }
        });

        return state;
    },
);
