import {AtomBinded} from "@reatom/core";
import {
    RefObject,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {useNavigate} from "react-router-dom";

import {calcElapsed} from "../fn";

export function useTimeout(ms: number): boolean {
    const [state, setState] = useState(false);

    useLayoutEffect(
        () => {
            const timeout = setTimeout(() => setState(true), ms);

            return () => clearTimeout(timeout);
        },
        [ms],
    );

    return state;
}

export function useAtomState<S>(atom: AtomBinded<S>): S {
    const [state, setState] = useState(atom.getState());
    useEffect(() => atom.subscribe(setState), []);

    return state;
}

export function useBackwardClick(): () => void {
    const nav = useNavigate();

    return useCallback(
        (e?: React.MouseEvent) => {
            e?.preventDefault();
            nav(-1);
        },
        [],
    );
}

export function usePreventFn<T extends {(...args: any[]): any}>(fn: T, deps: any[] = [])
    : (e: React.MouseEvent) => void {
    return useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            fn();
        },
        deps,
    );
}

const periodic = new WeakSet();
export function usePeriodicFn(fn: (cancel: () => void) => unknown, interval?: number | false): () => void {
    const [cancelled, set] = useState(!interval);
    const cancel = useCallback(() => set(false), []);

    useEffect(
        () => {
            if (!interval || cancelled) {
                return;
            }

            const wrapper = async (): Promise<void> => {
                try {
                    if (!periodic.has(fn)) {
                        periodic.add(fn);
                        await fn(cancel);
                    }
                } catch (error) {
                    clearInterval(id);
                }

                periodic.delete(fn);
            };

            const id = setInterval(wrapper, interval);

            return () => clearInterval(id);
        },
        [cancelled, interval],
    );

    return cancel;
}

export function useClickOutside<T extends HTMLElement>(onClick: () => void): RefObject<T> {
    const ref = useRef<T>(null);

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (ref.current && ref.current === event.target) {
            onClick();
        }
    }, []);

    useEffect(
        () => {
            document.addEventListener("click", handleClickOutside, true);

            return () => {
                document.removeEventListener("click", handleClickOutside, true);
            };
        },
        [],
    );

    return ref;
}

export function useSlideIndex(
    size: number,
    listener: (index: number) => unknown,
): {
        index: number;
        onNext: (e: React.MouseEvent<Element, MouseEvent>) => void;
        onPrev: (e: React.MouseEvent<Element, MouseEvent>) => void;
        size: number;
        set: (arg: number) => void;
        hasNext: boolean;
        hasPrev: boolean;
    } {
    const [index, setState] = useState(0);

    const set = useCallback(
        (current: number, inc: number) => {
            const value = current + inc;
            if (value >= 0 && value < size) {
                listener(value);

                return value;
            }

            return current;
        },
        [size],
    );

    const onPrev = usePreventFn(() => setState((n) => set(n, -1)), [size]);
    const onNext = usePreventFn(() => setState((n) => set(n, 1)), [size]);

    return {
        index,
        onNext,
        onPrev,
        size,
        set: (v: number) => setState(() => set(v, 0)),
        hasNext: index < size - 1,
        hasPrev: index > 0,
    };
}

export const useToggler = (initVal: any = false): [boolean, () => void, (val: boolean) => void] => {
    const [state, setState] = useState(Boolean(initVal));
    const toggle = useCallback(() => setState((prev) => !prev), []);

    return useMemo(() => [state, toggle, setState], [state]);
};

export const useElapseState = (sent?: Date, deps: any[] = []): [number, (newSent: Date | undefined) => void] => {
    const [elapsedState, updateElapsed] = useState(calcElapsed(sent));
    const [renewed, renew] = useState<Date>();
    const renewState = useCallback(
        (newSent: Date | undefined) => {
            updateElapsed(calcElapsed(newSent || sent));
            renew(newSent);
        },
        [],
    );

    useEffect(() => renew(undefined), deps);
    useEffect(
        () => {
            const i = setInterval(
                () => {
                    const nextState = calcElapsed(renewed || sent);
                    updateElapsed(nextState);

                    if (nextState <= 0) {
                        clearInterval(i);
                    }
                },
                1000,
            );

            return () => clearInterval(i);
        },
        [...deps, renewed],
    );

    return useMemo(() => [elapsedState, renewState], [elapsedState]);
};
