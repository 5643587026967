import {DocumentNode, print} from "graphql";
import {useEffect, useState} from "react";

import {Dispatcher} from "../event";
import {AsyncQuery} from "./AsyncQuery";
import {clearVariables, getOperationName} from "./fn";
import {MutationConfig, MutationDependency, QueryResponse} from "./interfaces";
import {QueryError} from "./QueryError";

export class Mutation<T, V = null> {
    readonly #name: string;
    readonly #query: DocumentNode;
    readonly #dependencies: MutationDependency[];
    readonly #dispatcher = new Dispatcher<{response: [T]}>();

    constructor(config: MutationConfig) {
        this.#query = config.query;
        this.#name = getOperationName(this.#query);
        this.#dependencies = config.dependencies ?? [];
    }

    public static factory<T, V = null>(query: DocumentNode, config?: Omit<MutationConfig, "query">): Mutation<T, V> {
        return new this<T, V>({query, ...config});
    }

    public useResult(): T | null {
        const [state, setState] = useState<T | null>(null);
        useEffect(() => this.#dispatcher.listen("response", setState), []);

        return state;
    }

    public async mutate(variables?: V): Promise<T> {
        try {
            const body = JSON.stringify({
                operationName: this.#name,
                query: print(this.#query),
                variables: clearVariables(variables),
            });

            const response = await fetch(AsyncQuery.url, {
                body,
                method: "POST",
                headers: {
                    "accept": "application/json",
                    "content-type": "application/json",
                },
                credentials: "include",
                mode: "cors",
            });

            const result = this.normalize(await response.json());
            this.#dispatcher.fire("response", result);
            await this.updateDependencies();

            return result;
        } catch (reason) {
            if (reason instanceof QueryError) {
                throw reason;
            }

            throw new QueryError(this.#query, {variables, reason});
        }
    }

    private updateDependencies(): Promise<void[]> {
        const ops = [];
        for (const dependency of this.#dependencies) {
            ops.push(dependency.updateAll());
        }

        return Promise.all(ops);
    }

    private normalize(response: QueryResponse<T>, variables?: V): T {
        if (!response.data || response.errors) {
            throw new QueryError(this.#query, {response, variables});
        }

        return response.data;
    }
}
