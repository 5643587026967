import {Suspense} from "react";
import * as React from "react";

const dsn = SENTRY_DSN;
const tracesSampleRate = +(Reflect.get(globalThis, "SENTRY_TRACES_SAMPLE_RATE") ?? "0.1");

const Sentry = React.lazy(() => import("./Sentry"));

export const SentryLoader: React.FC = () => {
    if (!dsn) {
        return null;
    }

    return (
        <Suspense>
            <Sentry dsn={dsn} tracesSampleRate={tracesSampleRate}/>
        </Suspense>
    );
};
