import * as React from "react";

import {lang} from "../config";

const format = new Intl.NumberFormat(lang, {maximumFractionDigits: 6});
const formatCompact = new Intl.NumberFormat("en", {notation: "compact"});

type NumberFormatProps = {
    value: number | string;
    compact?: boolean;
};

export const NumberFormat: React.FC<NumberFormatProps> = ({value, compact}) => {
    return compact
        ? <>{formatCompact.format(+value)}</>
        : <>{format.format(+value)}</>;
};
