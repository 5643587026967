import {useCallback, useEffect, useState} from "react";

export type CreateScriptState = {
    loaded: boolean;
    reason?: unknown;
};

const states = new Map<string, CreateScriptState>();

export function createScript(src: string, callback: () => unknown): CreateScriptState {
    const [state, setState] = useState<CreateScriptState>(states.get(src) ?? {loaded: false});
    const update = useCallback(
        (state: CreateScriptState) => {
            setState(state);
            states.set(src, state);

            if (state.loaded) {
                callback();
            }
        },
        [src, callback],
    );

    useEffect(
        () => {
            if (state.loaded) {
                callback();
            }
        },
        [state.loaded],
    );

    useEffect(
        () => {
            if (!states.has(src)) {
                states.set(src, state);

                const script = document.createElement("script");
                script.setAttribute("src", src);
                script.setAttribute("charset", "UTF-8");
                script.addEventListener("load", () => update({loaded: true}), {once: true});
                script.addEventListener("error", (reason) => update({loaded: false, reason}), {once: true});
                document.head.appendChild(script);

                return;
            }
        },
        [src],
    );

    return state;
}
