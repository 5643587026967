import {LinkTop} from "@com/LinkTop";
import * as React from "react";
import {Link} from "react-router-dom";

export const Footer: React.FC = () => {
    const value = "aW52ZXN0b3IucmVsYXRpb25zQHczZy5jYXBpdGFs";
    const copyMail = React.useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            const {currentTarget: target} = e;
            const copytext = document.createElement("input");
            copytext.value = atob(value);
            document.body.appendChild(copytext);
            copytext.select();
            document.execCommand("copy");
            document.body.removeChild(copytext);
            target.classList.add("active");
            setTimeout(() => target.classList.remove("active"), 1000);
        },
        [],
    );

    return (
        <footer id="contact" className="footer">
            <div className="container">
                <div className="top">
                    <LinkTop to="#" className="logo">
                        <img src="/assets/img/logo-big.svg" alt="" />
                    </LinkTop>
                    {/* <ul className="socials">
                        <li><a href=""><img src="/assets/img/icons/tg.svg" alt="" /></a></li>
                        <li><a href=""><img src="/assets/img/icons/twit.svg" alt="" /></a></li>
                        <li><a href=""><img src="/assets/img/icons/soc.svg" alt="" /></a></li>
                    </ul> */}
                    <ul className="links">
                        <li><Link to="/policy/">Privacy policy</Link></li>
                        <li><Link to="/terms/">Terms</Link></li>
                        <li><Link to="/careers/">Careers</Link></li>
                    </ul>
                </div>
                <div className="bottom">
                    <div className="copy">© {new Date().getFullYear()} | W3G.CAPITAL</div>
                    <div className="contact">
                        <a href="#" onClick={copyMail}>{atob(value)}</a>
                    </div>
                    <Link to="/funding-application" className="com-btn small"><span>Funding Application</span></Link>
                </div>
            </div>
        </footer>
    );
};
