import * as React from "react";

import {lang} from "../config";

const format = new Intl.NumberFormat(lang, {style: "currency", currency: "USD"});

type NumberFormatProps = {
    value: number | string;
};

export const MoneyFormat: React.FC<NumberFormatProps> = ({value}) => {
    return <>{format.format(+value)}</>;
};
