import {IconTarget1, IconTarget2, IconTarget3, IconTarget4} from "@com/icons";
import {ProgressPageRead} from "@com/progress";
import {OfferAnim2} from "assets/img/landing/animation";
import * as React from "react";
import {Link} from "react-router-dom";

import {partners} from "./mainConfig";

/* eslint-disable max-len */


export const Main: React.FC = () => {

    return (
        <ProgressPageRead>
            <main>
                <section className="main">
                    <div className="container">
                        <img src={OfferAnim2} alt="" />
                        <h1>W3G CAPITAL</h1>
                        <p>Safe investments in the WEB3 ecosystem. Raise your capital interest rates <b>maximum value</b> per annum on constantly available and bilateral operations to support in the DeFi & CeFi services liquidity.</p>
                        <Link className="com-btn" to="membership-application"><span>Membership Application</span></Link>
                    </div>
                </section>
                <section className="partners">
                    <div className="container">
                        <h2>Partners:</h2>
                        <div className="wrapper">
                            {partners.map(({href, image, alt}) => (
                                <a key={href} href={href} target="_blank" rel="noopener">
                                    <img src={image} alt={alt} />
                                </a>
                            ))}
                        </div>
                    </div>
                </section>
                <section id="about" className="about">
                    <div className="container">
                        <h2>ABOUT US</h2>
                        <p> We are experienced professionals with backgrounds in finance, technology, and cryptocurrency. We have a deep understanding of the industry and are well-versed in the legal, regulatory, and operational aspects of managing a cryptocurrency fund.  </p>
                        <p>Our unique approach to investment and focus on growth sets up ahead of the competition, as we record increasing number of partnerships with movers of the Web3 space. We draw from our experience and diverse backgrounds tested against objective analytics, and data to spot the best positioned projects to bootstrap with required liquidity and maximize returns.</p>
                    </div>
                </section>
                <section id="focuses" className="offer">
                    <div className="container">
                        <div className="text">
                            <h2>We offer more than just private equity investments</h2>
                            <a href="https://app.w3g.capital/" target="_blank" className="com-btn" rel="noopener"><span>Open account</span></a>
                        </div>
                        <div className="block">
                            <i />
                            <h3>Equity investment</h3>
                            <p>We fund and lead strategic development for talented projects and teams working on innovative solutions in the Web3 space such as trading, custody solutions, etc.</p>
                        </div>
                        <div className="block">
                            <i />
                            <h3>Liquidity provision</h3>
                            <p>Our smart contract architecture works with emerging and established DeFi and CeFi projects to ensure maximum yields from DeFi/CeFi farming, mining, and staking.</p>
                        </div>
                        <div className="block">
                            <i />
                            <h3>Infrastructure support</h3>
                            <p>We offer the most reliable decentralized Web3 architecture support on multiple protocols and layers to ensure steady uptime, secure validation, and custom monitoring.</p>
                        </div>
                    </div>
                </section>
                <section id="figures" className="targets">
                    <div className="container">
                        <h2>W3G Revenue Targets</h2>
                        <div className="wrapper">
                            <div className="picture">
                                <div className="tags">
                                    <IconTarget1/>
                                    <IconTarget2/>
                                    <IconTarget3/>
                                    <IconTarget4/>
                                </div>
                                <div className="img">
                                    <img src="/assets/img/landing/targets/image.svg" alt="" />
                                </div>
                            </div>
                            <div className="content">
                                <div className="row">
                                    <div className="block">
                                        <div className="count">25% APY</div>
                                        <div className="descr">Avarage annualized yield return</div>
                                    </div>
                                    <div className="block">
                                        <div className="count">$4.452.758 TVL</div>
                                        <div className="descr">Fund liquidity under management</div>
                                    </div>
                                </div>
                                <p>W3G reinvest native yield with an actively managed <br/> and hedged options spread strategy.</p>
                                <div className="tags">
                                    <div className="tag"><span>Multi-Transfers</span><i /></div>
                                    <div className="tag"><span>Compounding</span><i /></div>
                                    <div className="tag"><span>Transparency</span><i /></div>
                                </div>
                                <a href="https://app.w3g.capital/" target="_blank" className="com-btn" rel="noopener"><span>Open account</span></a>
                            </div>
                        </div>
                        <div className="supports">
                            <div className="block">
                                <div className="label">Supported assets</div>
                                <ul>
                                    <li><img src="/assets/img/landing/targets/support/icon-1.svg" alt="" /></li>
                                    <li><img src="/assets/img/landing/targets/support/icon-2.svg" alt="" /></li>
                                    <li><img src="/assets/img/landing/targets/support/icon-3.svg" alt="" /></li>
                                    <li><img src="/assets/img/landing/targets/support/icon-4.svg" alt="" /></li>
                                    <li><img src="/assets/img/landing/targets/support/icon-5.svg" alt="" /></li>
                                    <li><img src="/assets/img/landing/targets/support/icon-6.svg" alt="" /></li>
                                    <li><img src="/assets/img/landing/targets/support/icon-7.svg" alt="" /></li>
                                    <li><img src="/assets/img/landing/targets/support/icon-8.svg" alt="" /></li>
                                    <li><img src="/assets/img/landing/targets/support/icon-9.svg" alt="" /></li>
                                </ul>
                            </div>
                            <div className="block">
                                <div className="label">Supported networks</div>
                                <ul>
                                    <li><img src="/assets/img/landing/targets/network/icon-1.svg" alt="" /></li>
                                    <li><img src="/assets/img/landing/targets/network/icon-2.svg" alt="" /></li>
                                    <li><img src="/assets/img/landing/targets/network/icon-3.svg" alt="" /></li>
                                    <li><img src="/assets/img/landing/targets/network/icon-4.svg" alt="" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="mission" className="profit">
                    <div className="container">
                        <h2>The tenets of our profit</h2>
                        <p className="descr">W3G Hedge Fund is a investment tools for yield staking, farm strategy, and liquidity provision for WEB3. We deploy smart vaults that enable one-click access to institutional-grade options strategies while unlocking capital efficiency & liquidity for DeFi & CeFi options through yield-bearing LP options-backed asset tokens.</p>
                        <div className="subtitle">There are three main categories of users W3G CAPITAL is built for:</div>
                        <div className="wrapper">
                            <div className="block">
                                <i />
                                <div className="label">Web3Fi newcomers</div>
                                <p>Generate yields using our strategies and expertise to create unique investment opportunities. Join the community to learn more about our strategies.</p>
                            </div>
                            <div className="block">
                                <i />
                                <div className="label">Autonomous Depositors</div>
                                <p>Unlock deep liquidity for the decentralized web3 ecosystem through composable W3G products while earning yield.</p>
                            </div>
                            <div className="block">
                                <i />
                                <div className="label">Private investors</div>
                                <p>A service for trustless treasury management solutions to maximize growth.</p>
                            </div>
                        </div>
                    </div>
                </section>
                
                
                
            </main>
        </ProgressPageRead>
    );
};
