import {ProgressPageRead} from "@com/progress";
import * as React from "react";

/* eslint-disable max-len */

export const Careers: React.FC = () => {
    const value = "aGVsbG9AdzNnLmNhcGl0YWw=";
    const copyMail = React.useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            const {currentTarget: target} = e;
            const copytext = document.createElement("input");
            copytext.value = atob(value);
            document.body.appendChild(copytext);
            copytext.select();
            document.execCommand("copy");
            document.body.removeChild(copytext);
            target.classList.add("active");
            setTimeout(() => target.classList.remove("active"), 1000);
        },
        [],
    );

    return (
        <ProgressPageRead>
            <main>
                <section className="careers">
                    <div className="container">
                        <div className="head">
                            <h1 className="title">Careers</h1>
                            <div className="contact">
                            We are waiting for your resumes at
                                <a href="#" onClick={copyMail}>{atob(value)}</a>
                            </div>
                        </div>
                        <div className="cards">
                            <div className="card">
                                <div className="img">
                                    <img src="" alt="" />
                                </div>
                                <div className="name">PR Manager</div>
                                <p>We are looking for an experienced Public Relations Manager (PR Manager), who is familiar with crypto to join our fast-growing team. Our ideal candidate is an English fluent, skilled and crypto-driven PR Manager with an eye for detail and strong industry network and solid relationships with crypto reporters & media outlets.</p>
                                <div className="block">
                                    <div className="label">KEY RESPONSIBILITIES</div>
                                    <ul>
                                        <li>Develop a PR/Media communications plan including strategy, goals and budget
                                        </li>
                                        <li>Develop media relations strategy
                                        </li>
                                        <li>Leverage existing media relationships and ability to make free placements on paid resources due to high-quality content
                                        </li>
                                        <li>Cultivate new contacts within business and industry media;
                                        </li>
                                        <li>Evaluate opportunities for partnerships, sponsorships and advertising on an on-going basis
                                        </li>
                                        <li>Manage content for press releases
                                        </li>
                                        <li>Build relationships with thought leaders to grow industry awareness
                                        </li>
                                        <li>Maintain a keen understanding of industry trends
                                        </li>
                                    </ul>
                                </div>
                                <div className="block">
                                    <div className="label">JOB REQUIREMENT
                                    </div>
                                    <ul>
                                        <li>Proven at least 2 years of working experience in public relations required in the blockchain/crypto field
                                        </li>
                                        <li>Proven track record designing and executing successful public relations campaigns
                                        </li>
                                        <li>Strong relationships with media outlets
                                        </li>
                                        <li>Exceptional writing and editing skills
                                        </li>
                                        <li>Solid experience with social media including blogs, Twitter, LinkedIn, etc
                                        </li>
                                        <li>BA/MA degree in Marketing, Advertising, Communications or a related discipline
                                        </li>
                                        <li>Advanced or native English (C1+)
                                        </li>
                                        <li>Being data-driven and performing analysis of data to tell a story, drive campaigns and experiment with growth
                                        </li>
                                    </ul>
                                </div>
                                <div className="block">
                                    <div className="label">CONDITIONS
                                    </div>
                                    <p>Full time or Part-time + and bonus part

                                    </p>
                                </div>
                                <div className="block">
                                    <div className="label">BENEFITS
                                    </div>
                                    <ul>
                                        <li>Remote</li>
                                        <li>Free schedule
                                        </li>
                                        <li>Weekly payment in USDT salary + bonus
                                        </li>
                                        <li>Possibility of obtaining an option in project tokens
                                        </li>
                                        <li>Learn opportunities on the strong team
                                        </li>
                                        <li>Opportunities for relocation
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card">
                                <div className="img">
                                    <img src="" alt="" />
                                </div>
                                <div className="name">Research Analyst

                                </div>
                                <p>We’re hiring for skilled Research Analysts who are passionate about blockchain, decentralized finance and GameFi. The ideal candidate must have strong knowledge of GameFi ecosystem, data analytics and understanding of various token metrics. You will be the eyes and ears of market research, constantly plugged into the heartbeat of the GameFi market landscape.



                                </p>
                                <div className="block">
                                    <div className="label">KEY RESPONSIBILITIES
                                    </div>
                                    <ul>
                                        <li>Tracking trends across the GameFi ecosystem

                                        </li>
                                        <li>To create visuals that elegantly illustrate complex data
                                        </li>
                                        <li>Research all new games, project launches, discord and twitter activity, tokenomics, web3 user experiences and GameFi related topics, including similar projects, so that we can always be ahead of the curve

                                        </li>
                                        <li>Collaborate with biz dev, marketing and product teams in order to inform them about new GameFi trends and engaging in content creation
                                        </li>
                                    </ul>
                                </div>
                                <div className="block">
                                    <div className="label">JOB REQUIREMENT

                                    </div>
                                    <ul>
                                        <li>Passionate about the Web3 ecosystem and able to keep up with the fast-paced nature of the space


                                        </li>
                                        <li>Have participated in GameFi projects and understand their underlying properties and characteristics

                                        </li>
                                        <li>Solid understanding of crypto-economics and blockchain technologies generally

                                        </li>
                                        <li>Robust analytical skills that allow for identifying trends and assessing data in the crypto ecosystem

                                        </li>
                                        <li>Ability to aggregate information from a variety of sources and distill data into informed insights and conclusions

                                        </li>
                                        <li>Strong writing and communication skills

                                        </li>
                                        <li>Direct experience working in a remote-first environment and able to work independently with collaborating closely with a fast-growing team
                                        </li>
                                    </ul>
                                </div>
                                <div className="block">
                                    <div className="label">CONDITIONS
                                    </div>
                                    <p>Full time or Part-time + and bonus part

                                    </p>
                                </div>
                                <div className="block">
                                    <div className="label">BENEFITS
                                    </div>
                                    <ul>
                                        <li>Permanent job
                                        </li>
                                        <li>Remote format

                                        </li>
                                        <li>Free schedule

                                        </li>
                                        <li>Weekly payment in USDT salary + bonus
                                        </li>
                                        <li>Possibility of obtaining an option in project tokens
                                        </li>
                                        <li>Exchange of experience with a strong team

                                        </li>
                                        <li>Opportunities for relocation
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </ProgressPageRead>
    );
};
