import {useMemo} from "react";

import {PickRequired} from "..";
import {NavLinkData, PathMatch} from "./interface";

const defaultNav: string[] = ["*"];

export function getPathMatcher(path: string, match?: RegExp): PathMatch {
    if (match) {
        return {path, match};
    }

    const wildcard = path.endsWith("*");
    if (wildcard) {
        path = path.substring(0, path.length - 1);
        if (path.endsWith("/")) {
            path = path.substring(0, path.length - 1);
        }
    }

    const pattern = path === "/"
        ? path
        : path
            .replace(/\/?$/, "")
            .replace(/\//g, "\\/");

    const chains = [
        "^",
        pattern,
    ];

    if (wildcard) {
        chains.push("(\\/.+|/)?");
    }

    chains.push("$");

    return {path, match: new RegExp(chains.join(""))};
}

export function useNavLinks(
    links: NavLinkData[],
    permissions: string[] = defaultNav,
): PickRequired<NavLinkData, "id" | "match">[] {
    return useMemo(() => {
        const cache = links.map((link) => ({
            ...link,
            id: link.id ?? link.path,
            ...getPathMatcher(link.path, link.match),
        }));

        const allowed = permissions.map((path) => getPathMatcher(path).match);

        return cache.filter((link) => (
            allowed.some((matcher) => matcher.test(link.path))
        ));
    },
    [links, permissions],
    );
}
