import * as React from "react";

export type MatcherComponent<T> = React.FC<{
    value: T;
    children: React.ReactNode;
}>;

export function createMatcher<T>(source: T[]): MatcherComponent<T> {
    return ({value, children}) => source.includes(value)
        ? React.createElement(React.Fragment, {}, children)
        : null;
}
