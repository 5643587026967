import {ReactElement} from "react";

import {Nullable} from "../interfaces";

export function pass(condition: unknown, render: ReactElement): ReactElement | null {
    return condition ? render : null;
}

export function validate(expression: unknown, message?: string): asserts expression {
    if (!expression) {
        throw new Error(message ?? "Assertion failed");
    }
}

export function interrupt(expression: unknown, factory: () => Error): asserts expression {
    if (!expression) {
        throw factory();
    }
}

export function when<V, T>(value: V | undefined | null, then: (v: V) => Nullable<T>): T | undefined;
export function when<V, T, X>(value: Nullable<V>, then: (v: V) => Nullable<T>, mutate: (result: T) => X): X | undefined;
export function when<V, T, X>(value: Nullable<V>,
    then: (v: V) => Nullable<T>,
    mutate: (result: T) => T | X = (v) => v): T | X | undefined {
    if (!value) {
        return undefined;
    }

    const state = then(value);
    if (state) {
        return mutate(state);
    }

    return undefined;
}

export function whenNull<T, R>(value: T | null | undefined, fn: (value: T) => R, defaults: R): R {
    return value ? fn(value) : defaults;
}

export function denull<T>(value: T | null | undefined): T | undefined {
    return value === null ? undefined : value;
}

export function noop(..._: unknown[]): void {
    // nothing
}

export function listenTo<
    K extends keyof HTMLElementEventMap,
    T extends HTMLElement,
>(
    element: T | null,
    type: K,
    listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any,
    options?: boolean | AddEventListenerOptions,
): (() => void) | void {
    if (!element) {
        return;
    }

    element.addEventListener(type, listener, options);

    return () => element.removeEventListener(type, listener);
}

export function $<K extends keyof HTMLElementTagNameMap>(selectors: K): HTMLElementTagNameMap[K] | null;
export function $<K extends keyof SVGElementTagNameMap>(selectors: K): SVGElementTagNameMap[K] | null;
export function $<E extends Element = Element>(selectors: string): E | null;
export function $(selectors: any): any {
    return document.querySelector(selectors);
}

export function toNumber(value: string | number | null | undefined): number | undefined | void {
    if (typeof value === "number") {
        return value;
    }

    if (typeof value === "string") {
        return isNaN(+value) ? +value : undefined;
    }

    return;
}

export function unique(values: string[]): string[] {
    return [...new Set(values).values()];
}

export function capitalize(str: string): string {
    return str[0].toUpperCase() + str.slice(1);
}

export function isObject<T extends Record<any, any>>(value: T | unknown): value is T {
    return typeof value === "object" && value !== null;
}

export function skipEmptyString(value: Nullable<string>): string | undefined {
    return value ? value : undefined;
}

export function effect(mount: () => unknown, unmount: () => unknown) {
    return () => {
        mount();

        return () => {
            unmount();
        };
    };
}

export function calcElapsed(sent?: Date | null): number {
    if (!sent) {
        return 0;
    }

    const time = new Date(sent).getTime();

    return 60 - Math.round((Date.now() - time) / 1000);
}
