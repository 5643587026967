import {ProgressPageRead} from "@com/progress";
import {SendApplication} from "@query/config";
import * as React from "react";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";

export const MembershipApplication: React.FC = () => {
    const {register, handleSubmit} = useForm({shouldUseNativeValidation: true});
    const [thx, setThx] = React.useState(false);
    const onSubmit = handleSubmit((payload) => {
        SendApplication.mutate({payload});
        setThx(true);
    });

    return (
        <ProgressPageRead>
            <main>
                <section className="form">
                    <div className="container">
                        <h1 className="title">Membership Application</h1>
                        <form id="form" onSubmit={onSubmit}>
                            <input type="hidden" value="Membership Application" {...register("type")} />
                            <div className="row">
                                <input 
                                    className="com-inputn"
                                    autoComplete="off"
                                    placeholder="Your Name *"
                                    type="text"
                                    {...register("name", {
                                        required: true, 
                                        pattern: {
                                            value: /^.{2,}$/i,
                                            message: "Invalid name",
                                        },
                                    })}
                                />
                                <input 
                                    className="com-inputn"
                                    autoComplete="off"
                                    placeholder="Telegram username (starts with @) *"
                                    type="text"
                                    {...register("telegram", {
                                        required: true,
                                        pattern: {
                                            value: /^@[a-z0-9_\-.]{4,}$/,
                                            message: "Invalid telegram username",
                                        },
                                    })}
                                />
                            </div>
                            <div className="row">
                                <input 
                                    className="com-inputn"
                                    autoComplete="off"
                                    placeholder="Email *"
                                    type="email" 
                                    {...register("email", {required: true})}
                                />
                                <input 
                                    className="com-inputn"
                                    placeholder="Phone number *"
                                    autoComplete="off"
                                    type="tel" 
                                    {...register("phone", {
                                        required: true,
                                        pattern: {
                                            value: /^\+?[0-9\s\-()]{6,20}$/,
                                            message: "Invalid phone number",
                                        },
                                    })}
                                />
                            </div>
                            <div className="com-radio">
                                <div className="label">Have you previously invested in WEB3 or crypto-assets?</div>
                                <div className="wrap">
                                    <div className="radio">
                                        <input id="radio-1-1"
                                            value="Yes"
                                            {...register("radio-1", {required: false})}
                                            type="radio" name="radio-1" />
                                        <label htmlFor="radio-1-1">Yes</label>
                                    </div>
                                    <div className="radio">
                                        <input id="radio-1-2"
                                            value="No"
                                            {...register("radio-1", {required: false})} type="radio" />
                                        <label htmlFor="radio-1-2">No</label>
                                    </div>
                                    <div className="radio">
                                        <input id="radio-1-3" type="radio"
                                            value="Invest now"
                                            {...register("radio-1", {required: false})} />
                                        <label htmlFor="radio-1-3">Invest now</label>
                                    </div>
                                    <div className="radio">
                                        <input id="radio-1-4" type="radio"
                                            value="Invest in the funds"
                                            {...register("radio-1", {required: false})} />
                                        <label htmlFor="radio-1-4">Invest in the funds</label>
                                    </div>
                                </div>
                            </div>
                            <div className="com-radio">
                                <div className="label">What product do you want to invest in?</div>
                                <div className="wrap">
                                    <div className="radio">
                                        <input id="radio-2-1" type="checkbox"
                                            value="Private Equity"
                                            {...register("radio-2", {required: false})} />
                                        <label htmlFor="radio-2-1">Private Equity</label>
                                    </div>
                                    <div className="radio">
                                        <input id="radio-2-2" type="checkbox"
                                            value="Liquidity provision"
                                            {...register("radio-2", {required: false})} />
                                        <label htmlFor="radio-2-2">Liquidity provision</label>
                                    </div>
                                    <div className="radio">
                                        <input id="radio-2-3" type="checkbox"
                                            value="Infrastructure Staking"
                                            {...register("radio-2", {required: false})} />
                                        <label htmlFor="radio-2-3">Infrastructure Staking</label>
                                    </div>
                                    <div className="radio">
                                        <input id="radio-2-4" type="checkbox"
                                            value="Other products"
                                            {...register("radio-2", {required: false})} />
                                        <label htmlFor="radio-2-4">Other products</label>
                                    </div>
                                </div>
                            </div>
                            <div className="com-radio">
                                <div className="label">What product do you want to invest in?</div>
                                <div className="wrap">
                                    <div className="radio">
                                        <input id="radio-3-1" type="radio"
                                            value="Easily. Exchange regularly"
                                            {...register("radio-3", {required: false})} />
                                        <label htmlFor="radio-3-1">Easily. Exchange regularly</label>
                                    </div>
                                    <div className="radio">
                                        <input id="radio-3-2" type="radio"
                                            value="Not easy. Few trusted services"
                                            {...register("radio-3", {required: false})} />
                                        <label htmlFor="radio-3-2">Not easy. Few trusted services</label>
                                    </div>
                                    <div className="radio">
                                        <input id="radio-3-3" type="radio"
                                            value="Difficult. Exchange is difficult"
                                            {...register("radio-3", {required: false})} />
                                        <label htmlFor="radio-3-3">Difficult. Exchange is difficult</label>
                                    </div>
                                </div>
                            </div>
                            <input {...register("invested", {required: true})}
                                className="com-inputn"
                                autoComplete="off"
                                placeholder="What assets have you previously invested in? *"
                                type="text" />
                            <input {...register("usd", {required: true})}
                                className="com-inputn"
                                autoComplete="off"
                                placeholder="How much in USD are you want to invest? *"
                                min={1}
                                type="number" />
                            <input {...register("nationality", {required: false})}
                                className="com-inputn"
                                placeholder="What is your nationality?"
                                autoComplete="off"
                                type="text" />
                            <input id="formCountry"
                                {...register("country", {required: false})}
                                className="com-inputn"
                                autoComplete="off"
                                placeholder="What country are you living now?"
                                type="text" />
                            <input id="formJoin"
                                {...register("join", {required: false})}
                                className="com-inputn"
                                autoComplete="off"
                                placeholder="Why do you want to join W3G.CAPITAL FUND?"
                                type="text" />
                            <button className="com-btn"><span>Submit</span></button>
                        </form>
                    </div>
                </section>
                {thx &&
                <div className="thx" onClick={() => setThx(false)}>
                    <div className="content">
                        <div className="title">Well done!</div>
                        <div className="descr">
                            Your application has been accepted for consideration.
                            Track the verification status via contacts, it usually doesn't take long time.
                        </div>
                        <Link to="/" className="com-btn small"><span>Back Home Page</span></Link>
                    </div>
                </div>
                }
            </main>
        </ProgressPageRead>
    );
};
