import {IPageMetadata, IRouteMetadata} from "@app/types";
import * as React from "react";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";

import {getRouteMetadata} from "./fn";

type Props = {
    routes?: readonly IRouteMetadata[] | null;
    children?: React.ReactNode;
    alt: IPageMetadata;
};

export const WithRouteMeta: React.FC<Props> = ({routes = [], alt}) => {
    const p = useLocation();
    const metadata = getRouteMetadata(p.pathname, routes);
    const {description, title, keywords} = metadata ?? alt;

    return (
        <Helmet key={p.pathname}>
            <title>{title}</title>
            <meta name="description" content={description}/>
            <meta name="keywords" content={keywords}/>
        </Helmet>
    );
};
