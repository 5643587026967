import {IPageMetadata} from "@app/types";
import * as React from "react";
import {Helmet} from "react-helmet";

type Props = {
    metadata?: IPageMetadata;
    alt?: IPageMetadata;
};

export const WithMeta: React.FC<Props> = ({metadata, alt}) => {
    const {description, title, keywords} = metadata ?? alt ?? {};

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description}/>
            <meta name="keywords" content={keywords}/>
        </Helmet>
    );
};
