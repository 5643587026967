import {ScrollTop} from "@app/com";
import {IPageMetadata, IRouteMetadata} from "@app/types";
import {WithRouteMeta} from "@com/meta";
import {SignIn} from "@pages/Authorization/SignIn";
import {Careers} from "@pages/Careers";
import {FundingApplication} from "@pages/FundingApplication";
import {Main} from "@pages/Main";
import {MembershipApplication} from "@pages/MembershipApplication";
import {Policy} from "@pages/Policy";
import {Terms} from "@pages/Terms";
import * as React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import {Layout} from "./Layout";

const metadata: IPageMetadata = {
    title: TITLE,
    description: DESCRIPTION,
};

/* eslint-disable max-len */
const routes: IRouteMetadata[] = [
    {
        host: location.hostname,
        route: "/authorization/sign-in",
        title: "W3G.CAPITAL – Alternative Investments | Login",
        description: "W3G is a digital asset investment and advisory firm with a focus on deep-stage web3. Raise your capital interest rates on constantly available and bilateral finance operations!",
    },
    {
        host: location.hostname,
        route: "/membership-application",
        title: "W3G.CAPITAL – Alternative Investments | Membership application",
        description: "W3G is a digital asset investment and advisory firm with a focus on deep-stage web3. Raise your capital interest rates on constantly available and bilateral finance operations!",
    },
    {
        host: location.hostname,
        route: "/funding-application",
        title: "W3G.CAPITAL – Alternative Investments | Funding application",
        description: "W3G is a digital asset investment and advisory firm with a focus on deep-stage web3. Raise your capital interest rates on constantly available and bilateral finance operations!",
    },
    {
        host: location.hostname,
        route: "/careers",
        title: "W3G.CAPITAL – Alternative Investments | Careers",
        description: "W3G is a digital asset investment and advisory firm with a focus on deep-stage web3. Raise your capital interest rates on constantly available and bilateral finance operations!",
    },
    {
        host: location.hostname,
        route: "/policy",
        title: "W3G.CAPITAL – Alternative Investments | Policy",
        description: "W3G is a digital asset investment and advisory firm with a focus on deep-stage web3. Raise your capital interest rates on constantly available and bilateral finance operations!",
    },
    {
        host: location.hostname,
        route: "/terms",
        title: "W3G.CAPITAL – Alternative Investments | Terms",
        description: "W3G is a digital asset investment and advisory firm with a focus on deep-stage web3. Raise your capital interest rates on constantly available and bilateral finance operations!",
    },
];

const Application: React.FC = () => {
    return (
        <BrowserRouter>
            <ScrollTop/>
            <WithRouteMeta routes={routes} alt={metadata}/>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Main/>}/>
                    <Route path="/membership-application" element={<MembershipApplication/>}/>
                    <Route path="/funding-application" element={<FundingApplication/>}/>
                    <Route path="/authorization/sign-in" element={<SignIn/>}/>
                    <Route path="/careers" element={<Careers/>}/>
                    <Route path="/terms" element={<Terms/>}/>
                    <Route path="/policy" element={<Policy/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default Application;
