import * as React from "react";

import {lang} from "../config";

const format = new Intl.DateTimeFormat(lang, {});

type NumberFormatProps = {
    value: number | string | Date;
};

export const DateFormat: React.FC<NumberFormatProps> = ({value}) => {
    return <>{format.format(new Date(value))}</>;
};
