import {DocumentNode} from "graphql";

import {QueryResponse} from "./interfaces";

export type QueryErrorArguments = {
    response?: QueryResponse<unknown>;
    variables?: unknown;
    reason?: unknown;
};

export class QueryError extends Error {
    readonly #query: DocumentNode;
    readonly #response?: QueryResponse<unknown>;
    readonly #variables?: unknown;
    readonly #reason?: unknown;
    readonly #errors: {message: string}[] = [];

    constructor(query: DocumentNode, args: QueryErrorArguments = {}) {
        super();

        this.#query = query;
        this.#response = args.response;
        this.#variables = args.variables;
        this.#reason = args.reason;

        if (this.#reason instanceof Error) {
            this.message = this.#reason.message;
        }

        if (this.#response?.errors) {
            this.#errors.push(...this.#response.errors);
        }

        if (!this.message) {
            const [error] = this.#errors;
            this.message = error?.message ?? "Unexpected error";
        }
    }
}
