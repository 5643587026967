/* eslint-disable max-len */
import * as React from "react"; 

export const IconMainBg: React.FC = () => (
    <svg width="1136" height="863" viewBox="0 0 1136 863" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5" filter="url(#filter0_f_36_1444)">
            <path d="M755.577 595.961C699.134 693.724 481.779 673.905 353.063 599.59C224.346 525.276 165.757 385.779 222.2 288.016C278.644 190.253 428.746 171.244 557.463 245.559C686.18 319.873 812.021 498.198 755.577 595.961Z" fill="url(#paint0_linear_36_1444)"/>
        </g>
        <g opacity="0.5" filter="url(#filter1_f_36_1444)">
            <path d="M664.058 595.961C636.412 548.076 698.049 460.732 761.095 424.332C824.142 387.932 897.663 397.243 925.309 445.128C952.955 493.013 924.258 561.339 861.212 597.739C798.166 634.138 691.704 643.846 664.058 595.961Z" fill="url(#paint1_linear_36_1444)"/>
        </g>
        <defs>
            <filter id="filter0_f_36_1444" x="0.712891" y="0.209961" width="968.729" height="862.237" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_36_1444"/>
            </filter>
            <filter id="filter1_f_36_1444" x="457.267" y="202.12" width="678.567" height="626.406" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_36_1444"/>
            </filter>
            <linearGradient id="paint0_linear_36_1444" x1="291.119" y1="155.707" x2="761.156" y2="452.604" gradientUnits="userSpaceOnUse">
                <stop stopColor="#ED7117"/>
                <stop offset="0.855" stopColor="white"/>
                <stop offset="1" stopColor="#F7BE51"/>
            </linearGradient>
            <linearGradient id="paint1_linear_36_1444" x1="964.554" y1="506.765" x2="723.501" y2="633.436" gradientUnits="userSpaceOnUse">
                <stop stopColor="#ED7117"/>
                <stop offset="1" stopColor="#080A0F"/>
            </linearGradient>
        </defs>
    </svg>    
);

export const IconAboutBg: React.FC = () => (
    <svg width="832" height="1195" viewBox="0 0 832 1195" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.16" filter="url(#filter0_f_15108_1667)">
            <path d="M607 884.015C510.421 1051.3 152.032 1011.17 -68.2126 884.015C-288.457 756.857 -388.707 518.168 -292.128 350.888C-195.549 183.607 61.2874 151.082 281.532 278.24C501.776 405.398 703.579 716.735 607 884.015Z" fill="url(#paint0_linear_15108_1667)"/>
        </g>
        <defs>
            <filter id="filter0_f_15108_1667" x="-528.895" y="0.644531" width="1360.69" height="1194.29" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_15108_1667"/>
            </filter>
            <linearGradient id="paint0_linear_15108_1667" x1="-174.203" y1="124.498" x2="630.067" y2="632.51" gradientUnits="userSpaceOnUse">
                <stop offset="5.32144e-07" stopColor="#ED7117"/>
                <stop offset="0.43" stopColor="white" stopOpacity="0.57"/>
                <stop offset="1" stopColor="#F7BE51"/>
            </linearGradient>
        </defs>
    </svg>

);

export const IconTarget1: React.FC = () => (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5" d="M5.11961 4.76903C3.16699 6.72165 3.16699 9.86435 3.16699 16.1497C3.16699 22.4351 3.16699 25.5778 5.11961 27.5305C7.07223 29.4831 10.2149 29.4831 16.5003 29.4831C22.7857 29.4831 25.9284 29.4831 27.881 27.5305C29.8337 25.5778 29.8337 22.4351 29.8337 16.1497C29.8337 9.86435 29.8337 6.72165 27.881 4.76903C25.9284 2.81641 22.7857 2.81641 16.5003 2.81641C10.2149 2.81641 7.07223 2.81641 5.11961 4.76903Z" fill="#FFE55E"/>
        <path d="M9.5 9.48437C9.5 8.93209 9.05229 8.48438 8.5 8.48438C7.94772 8.48438 7.5 8.93209 7.5 9.48437L7.5 22.8177C7.5 23.37 7.94772 23.8177 8.5 23.8177C9.05228 23.8177 9.5 23.37 9.5 22.8177L9.5 9.48437Z" fill="#FFE55E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.1262 10.1106C13.5168 9.72008 14.1499 9.72008 14.5404 10.1106L16.4093 11.9794C17.1997 11.4559 18.1476 11.151 19.1667 11.151C20.1857 11.151 21.1336 11.4559 21.9241 11.9794L23.7929 10.1106C24.1834 9.72008 24.8166 9.72008 25.2071 10.1106C25.5976 10.5011 25.5976 11.1343 25.2071 11.5248L23.3383 13.3937C23.8618 14.1841 24.1667 15.132 24.1667 16.151C24.1667 17.1701 23.8618 18.118 23.3383 18.9084L25.2071 20.7773C25.5976 21.1678 25.5976 21.801 25.2071 22.1915C24.8166 22.582 24.1834 22.582 23.7929 22.1915L21.9241 20.3226C21.1336 20.8462 20.1857 21.151 19.1667 21.151C18.1476 21.151 17.1997 20.8462 16.4093 20.3226L14.5404 22.1915C14.1499 22.582 13.5168 22.582 13.1262 22.1915C12.7357 21.801 12.7357 21.1678 13.1262 20.7773L14.9951 18.9084C14.4715 18.118 14.1667 17.1701 14.1667 16.151C14.1667 15.132 14.4715 14.1841 14.9951 13.3937L13.1262 11.5248C12.7357 11.1343 12.7357 10.5011 13.1262 10.1106ZM16.1667 16.151C16.1667 14.4942 17.5098 13.151 19.1667 13.151C20.8235 13.151 22.1667 14.4942 22.1667 16.151C22.1667 17.8079 20.8235 19.151 19.1667 19.151C17.5098 19.151 16.1667 17.8079 16.1667 16.151Z" fill="#FFE55E"/>
    </svg>

);

export const IconTarget2: React.FC = () => (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5" d="M28.3836 8.00262C28.833 8.67522 28.833 9.61153 28.833 11.4841V28.4841H29.4997C30.052 28.4841 30.4997 28.9319 30.4997 29.4841C30.4997 30.0364 30.052 30.4841 29.4997 30.4841H2.83301C2.28072 30.4841 1.83301 30.0364 1.83301 29.4841C1.83301 28.9319 2.28072 28.4841 2.83301 28.4841H3.49967V11.4841C3.49967 9.61153 3.49967 8.67522 3.94909 8.00262C4.14364 7.71145 4.39365 7.46145 4.68482 7.26689C5.27312 6.8738 8.06715 6.82453 9.50903 6.81836C9.50355 7.20688 9.5036 7.61286 9.50365 8.03147V8.03148L9.50366 8.15081V9.81747H6.16634C5.61406 9.81747 5.16634 10.2652 5.16634 10.8175C5.16634 11.3698 5.61406 11.8175 6.16634 11.8175H9.50366V13.8175H6.16634C5.61406 13.8175 5.16634 14.2652 5.16634 14.8175C5.16634 15.3698 5.61406 15.8175 6.16634 15.8175H9.50366V17.8175H6.16634C5.61406 17.8175 5.16634 18.2652 5.16634 18.8175C5.16634 19.3698 5.61406 19.8175 6.16634 19.8175H9.50366V28.4841H22.8369V19.8175H26.1663C26.7186 19.8175 27.1663 19.3698 27.1663 18.8175C27.1663 18.2652 26.7186 17.8175 26.1663 17.8175H22.8369V15.8175H26.1663C26.7186 15.8175 27.1663 15.3698 27.1663 14.8175C27.1663 14.2652 26.7186 13.8175 26.1663 13.8175H22.8369V11.8175H26.1663C26.7186 11.8175 27.1663 11.3698 27.1663 10.8175C27.1663 10.2652 26.7186 9.81747 26.1663 9.81747H22.8369V8.15081L22.8369 8.03149C22.837 7.61288 22.837 7.20689 22.8315 6.81836C24.2734 6.82453 27.0596 6.8738 27.6479 7.26689C27.939 7.46145 28.189 7.71145 28.3836 8.00262Z" fill="#FFE55E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.833 2.81641H17.4997C20.0138 2.81641 21.2709 2.81641 22.052 3.59745C22.833 4.3785 22.833 5.63558 22.833 8.14974V28.4831H24.833H28.833H29.4997C30.052 28.4831 30.4997 28.9308 30.4997 29.4831C30.4997 30.0354 30.052 30.4831 29.4997 30.4831H2.83301C2.28072 30.4831 1.83301 30.0354 1.83301 29.4831C1.83301 28.9308 2.28072 28.4831 2.83301 28.4831H3.49967H7.49967H9.49967V8.14974C9.49967 5.63558 9.49967 4.3785 10.2807 3.59745C11.0618 2.81641 12.3188 2.81641 14.833 2.81641ZM16.1663 24.4831C16.7186 24.4831 17.1663 24.9308 17.1663 25.4831V28.4831H15.1663V25.4831C15.1663 24.9308 15.6141 24.4831 16.1663 24.4831ZM13.4997 18.8164C12.9474 18.8164 12.4997 19.2641 12.4997 19.8164C12.4997 20.3687 12.9474 20.8164 13.4997 20.8164H18.833C19.3853 20.8164 19.833 20.3687 19.833 19.8164C19.833 19.2641 19.3853 18.8164 18.833 18.8164H13.4997ZM12.4997 15.8164C12.4997 15.2641 12.9474 14.8164 13.4997 14.8164H18.833C19.3853 14.8164 19.833 15.2641 19.833 15.8164C19.833 16.3687 19.3853 16.8164 18.833 16.8164H13.4997C12.9474 16.8164 12.4997 16.3687 12.4997 15.8164ZM13.4997 11.4831C12.9474 11.4831 12.4997 11.9308 12.4997 12.4831C12.4997 13.0354 12.9474 13.4831 13.4997 13.4831H18.833C19.3853 13.4831 19.833 13.0354 19.833 12.4831C19.833 11.9308 19.3853 11.4831 18.833 11.4831H13.4997ZM12.4997 8.48307C12.4997 7.93079 12.9474 7.48307 13.4997 7.48307H18.833C19.3853 7.48307 19.833 7.93079 19.833 8.48307C19.833 9.03536 19.3853 9.48307 18.833 9.48307H13.4997C12.9474 9.48307 12.4997 9.03536 12.4997 8.48307Z" fill="#FFE55E"/>
    </svg>

);

export const IconTarget3: React.FC = () => (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5" d="M4.5 13.4831C4.5 8.45476 4.5 5.9406 6.0621 4.3785C7.62419 2.81641 10.1384 2.81641 15.1667 2.81641H17.8333C22.8616 2.81641 25.3758 2.81641 26.9379 4.3785C28.5 5.9406 28.5 8.45476 28.5 13.4831V18.8164C28.5 23.8447 28.5 26.3589 26.9379 27.921C25.3758 29.4831 22.8616 29.4831 17.8333 29.4831H15.1667C10.1384 29.4831 7.62419 29.4831 6.0621 27.921C4.5 26.3589 4.5 23.8447 4.5 18.8164V13.4831Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.167 16.1504C10.167 15.5981 10.6147 15.1504 11.167 15.1504H21.8337C22.3859 15.1504 22.8337 15.5981 22.8337 16.1504C22.8337 16.7027 22.3859 17.1504 21.8337 17.1504H11.167C10.6147 17.1504 10.167 16.7027 10.167 16.1504Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.167 10.8164C10.167 10.2641 10.6147 9.81641 11.167 9.81641H21.8337C22.3859 9.81641 22.8337 10.2641 22.8337 10.8164C22.8337 11.3687 22.3859 11.8164 21.8337 11.8164H11.167C10.6147 11.8164 10.167 11.3687 10.167 10.8164Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.167 21.4844C10.167 20.9321 10.6147 20.4844 11.167 20.4844H17.8337C18.3859 20.4844 18.8337 20.9321 18.8337 21.4844C18.8337 22.0367 18.3859 22.4844 17.8337 22.4844H11.167C10.6147 22.4844 10.167 22.0367 10.167 21.4844Z" fill="white"/>
    </svg>

);

export const IconTarget4: React.FC = () => (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5" d="M13.8327 26.8177H19.166C24.1943 26.8177 26.7085 26.8177 28.2706 25.2556C29.8327 23.6935 29.8327 21.1794 29.8327 16.151C29.8327 15.5618 29.8305 14.0071 29.828 13.4844H3.16634C3.16383 14.0071 3.16602 15.5618 3.16602 16.151C3.16602 21.1794 3.16602 23.6935 4.72812 25.2556C6.29022 26.8177 8.80437 26.8177 13.8327 26.8177H13.8327Z" fill="white"/>
        <path d="M13.8268 5.48438H19.1739C24.2151 5.48438 26.7358 5.48438 28.3019 6.97209C29.4295 8.04325 29.7453 9.58447 29.8337 12.151V13.4844H3.16699V12.151C3.25539 9.58447 3.57112 8.04325 4.69874 6.97209C6.26486 5.48438 8.7855 5.48438 13.8268 5.48438Z" fill="white"/>
        <path d="M17.1667 20.4844C16.6144 20.4844 16.1667 20.9321 16.1667 21.4844C16.1667 22.0367 16.6144 22.4844 17.1667 22.4844H19.1667C19.719 22.4844 20.1667 22.0367 20.1667 21.4844C20.1667 20.9321 19.719 20.4844 19.1667 20.4844H17.1667Z" fill="white"/>
        <path d="M8.5 20.4844C7.94772 20.4844 7.5 20.9321 7.5 21.4844C7.5 22.0367 7.94772 22.4844 8.5 22.4844H13.8333C14.3856 22.4844 14.8333 22.0367 14.8333 21.4844C14.8333 20.9321 14.3856 20.4844 13.8333 20.4844H8.5Z" fill="white"/>
    </svg>
);