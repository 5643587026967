import {Partner1, Partner2, Partner3, Partner4, Partner5} from "assets/img/landing/partners";

export const partners = [
    {
        href: "#",
        image: Partner1,
        alt: "Ecochain",
    },
    {
        href: "https://bankingcore.cloud/",
        image: Partner2,
        alt: "Core",
    },
    {
        href: "https://w3g.group/",
        image: Partner3,
        alt: "W3g group",
    },
    {
        href: "https://gwcapital.ch/",
        image: Partner4,
        alt: "Gw capital",
    },
    {
        href: "#",
        image: Partner5,
        alt: "Mass Adoption",
    },

];