import {DocumentNode, getOperationAST} from "graphql";

export function getOperationName(query: DocumentNode): string {
    const operationAST = getOperationAST(query);

    return operationAST?.name?.value
        ?? operationAST?.operation
        ?? "query";
}

function isObject(value: unknown): value is Record<any, unknown> {
    return typeof value === "object" && value !== null;
}

function isArray(value: unknown): value is any[] {
    return Array.isArray(value);
}

export function clearVariables<T extends any | any[]>(value?: T): T {
    if (value === undefined) {
        return null as any;
    }

    if (isObject(value)) {
        if (isArray(value)) {
            return value.map(clearVariables) as T;
        }

        if (value instanceof Date) {
            return value;
        }

        const entries = Object.entries(value).map(([k, v]) => [k, clearVariables(v)]);
        if (entries.every(([, mayNull]) => mayNull === null)) {
            return undefined as any;
        }

        return Object.fromEntries(entries) as T;
    }

    return value as T;
}
