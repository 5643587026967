import {DocumentNode, print} from "graphql";
import * as React from "react";

import {ENDPOINT} from "../config";
import {Dispatcher} from "../event";
import {noop, when} from "../fn";
import {Nullable} from "../interfaces";
import {ClientContext} from "./ClientContext";
import {clearVariables, getOperationName} from "./fn";
import {AsyncQueryConfig, QueryResponse, TransformQuery} from "./interfaces";
import {QueryError} from "./QueryError";

type Cache<T, V = null> = {
    key: string;
    variables?: V;
    error?: Error;
    data?: NonNullable<T>;
};

export class AsyncQuery<T, V = null> {
    public readonly id: string;

    readonly #name: string;
    readonly #query: DocumentNode;
    readonly #transform: TransformQuery;
    readonly #key: (variables: V) => string;

    readonly #refs = new Map<string, number>();
    readonly #cache = new Map<string, Cache<T, V>>();
    readonly #queries = new Map<string, Promise<unknown>>();

    readonly #dispatcher = new Dispatcher<Record<string, [Cache<T, V>]>>();

    protected url: string;
    public static url: string;

    constructor(config: AsyncQueryConfig<V>) {
        const index = [Date.now(), Math.random()].join(".");
        this.#query = config.query;
        this.#name = getOperationName(config.query);
        this.#key = config.key ?? (() => this.#name);
        this.id = `${index}:${this.#name}`;
        this.#transform = config.transform;
        this.url = ENDPOINT;
    }

    public static factory<T, V = null>(query: DocumentNode, key?: (variables: V) => string): AsyncQuery<T, V> {
        return new AsyncQuery<T, V>({query, key, transform: (v) => v});
    }

    public as<TNext>(transform: (data: T) => TNext): AsyncQuery<TNext, V> {
        return new AsyncQuery<TNext, V>({
            query: this.#query,
            key: this.#key,
            transform: transform as TransformQuery,
        });
    }

    public async update(variables?: V): Promise<void> {
        const key = this.#key(variables as V);
        this.#dispatcher.fire(key, await this.fetch(variables));
    }

    public async updateAll(): Promise<void> {
        const ops = [];
        for (const {key, variables} of this.#cache.values()) {
            ops.push(this.fetch(variables).then((cache) => this.#dispatcher.fire(key, cache)));
        }

        await Promise.all(ops);
    }

    public when<E>(expr: Nullable<E>, fn: (variables: E) => V): NonNullable<T> | undefined {
        this.useEndpointURL();

        const variables = when(expr, fn);
        const maybeKey = when(variables, (v) => this.#key(v as V));
        React.useLayoutEffect(() => when(maybeKey, (key) => this.ref(key)), [maybeKey]);
        React.useEffect(() => when(maybeKey, (key) => this.unref(key)), [maybeKey]);

        if (!maybeKey) {
            return;
        }

        return this.suspense(maybeKey, variables);
    }

    public query(variables?: V): NonNullable<T> {
        this.useEndpointURL();

        const key = this.#key(variables as V);
        React.useLayoutEffect(() => this.ref(key), [key]);
        React.useEffect(() => this.unref(key), [key]);

        return this.suspense(key, variables);
    }

    public watch(variables?: V): NonNullable<T> {
        const [initialVariables] = React.useState(variables);
        const key = this.#key(variables as V);
        const data = this.query(initialVariables);
        const [state, setState] = React.useState<Cache<T, V>>({data, key, variables});

        React.useEffect(() => this.#dispatcher.listen(key, setState), [key]);
        React.useEffect(() => noop(this.prefetch(variables).then(setState)), [key]);

        return this.validate(state);
    }

    public async prefetch(variables?: V): Promise<Cache<T, V>> {
        const key = this.#key(variables as V);
        const cache = this.#cache.get(key) ?? await this.fetch(variables);

        return cache;
    }

    private suspense(key: string, variables?: V): NonNullable<T> {
        const cache = this.#cache.get(key);
        if (cache) {
            return this.validate(cache);
        }

        throw this.deduplicate(key, () => this.fetch(variables));
    }

    private async fetch(variables?: V): Promise<Cache<T, V>> {
        const key = this.#key(variables as V);

        try {
            const body = JSON.stringify({
                operationName: this.#name,
                query: print(this.#query),
                variables: clearVariables(variables),
            });

            const response = await fetch(this.url, {
                body,
                method: "POST",
                headers: {
                    "accept": "application/json",
                    "content-type": "application/json",
                },
                credentials: "include",
                mode: "cors",
            });

            if (response.status !== 200) {
                const type = response.headers.get("content-type");
                if (type?.startsWith("application/json")) {
                    const data = await response.json();
                    if (data.error) {
                        const errors = [{message: data.error}];

                        throw new QueryError(this.#query, {response: {errors}, variables});
                    }
                }
            }

            const cache = this.createCache(key, await response.json(), variables);
            this.#cache.set(key, cache);

            return cache;
        } catch (reason) {
            const cache = {
                key,
                variables,
                error: new QueryError(this.#query, {variables, reason}),
            };

            this.#cache.set(key, cache);

            return cache;
        }
    }

    private createCache(key: string, response: QueryResponse<unknown>, variables?: V): Cache<T, V> {
        if (!response.data || response.errors) {
            return {key, variables, error: new QueryError(this.#query, {response, variables})};
        }

        return {
            key,
            variables,
            data: this.#transform(response.data) as NonNullable<T>,
        };
    }

    private ref(key: string): void {
        this.#refs.set(key, this.getRefCount(key) + 1);
    }

    private unref(key: string): () => void {
        return (): void => {
            this.#refs.set(key, this.getRefCount(key) - 1);
            if (!this.getRefCount(key)) {
                this.#cache.delete(key);
                this.#refs.delete(key);
            }
        };
    }

    private validate(cache: Cache<T, V>): NonNullable<T> {
        if (cache.error) {
            throw cache.error;
        }

        return cache.data as NonNullable<T>;
    }

    private deduplicate(key: string, fn: () => Promise<unknown>): Promise<unknown> {
        const pending = this.#queries.get(key) ?? fn()
            .finally(() => this.#queries.delete(key));

        if (!this.#queries.has(key)) {
            this.#queries.set(key, pending);
        }

        return pending;
    }

    private getRefCount(key: string): number {
        return this.#refs.get(key) ?? 0;
    }

    private useEndpointURL(): void {
        this.url = React.useContext(ClientContext);
        AsyncQuery.url = this.url;
    }
}

